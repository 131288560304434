<template>
	<Header />
	<div id="app">
		<div class="layout layout--rating-collection">
			<div class="main-wrapper">
				<main class="main">
					<slot />
				</main>
			</div>
		</div>
		<Footer no-start />
	</div>
</template>
